.reviewsContainer {
  text-align: center;
  margin: 4rem 0rem;
}

.reviewsHeading {
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: #6c63ff;
  background: linear-gradient(
    to bottom right,
    #4710ff 47.1%,
    #4f0aff 52.9%,
    #5705ff 58.8%,
    #5f00ff 64.7%,
    #ba56fd 85%,
    #b660f1 77.5%,
    #ca6ef5 84.5%,
    #db7af8 91.9%,
    #db7af8
  );
  font-weight: bold !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: slide-up 2s infinite;
}

.reviews {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.reviewCard {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 1.5rem;
  width: 300px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating {
  color: #f5c518;
  font-size: 2rem; /* Yıldızların boyutunu büyütüyoruz */
  margin-bottom: 1rem;
}

.reviewTitle {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #333;
}

.reviewContent {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #555;
  flex-grow: 1; /* Metin alanını esnek hale getiriyoruz */
}

.reviewAuthor {
  display: flex;
  align-items: center;
}

.reviewAuthor img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.authorName {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.authorPosition {
  font-size: 0.875rem;
  color: #777;
}

@media (max-width: 768px) {
  .reviews {
    flex-direction: column;
    align-items: center;
  }

  .reviewCard {
    max-width: 400px;
  }
  .reviewsContainer,
  .reviewsHeading {
    margin-top: 0 !important;
  }
  .reviewsHeading {
    margin-bottom: 1rem !important;
  }
}
