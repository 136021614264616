html {
  line-height: 1.15; /* Better line-height default */
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
}

body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

.profileCard,
.imageCard,
.loginCard {
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out !important;
}

hr {
  box-sizing: content-box; /* Adjust for the box model */
  height: 0; /* Remove default border */
}

pre {
  font-family: monospace, monospace; /* Fix font-family */
  font-size: 1em; /* Match default font-size */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline; /* Better default style */
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* Fix font-family */
  font-size: 1em; /* Match default font-size */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* Inherit font */
  font-size: 100%; /* Match default font-size */
  line-height: 1.15; /* Better line-height default */
  margin: 0; /* Remove default margin */
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.customStyle {
  background-color: white;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  .wrapper {
    max-width: 1180px;
  }
}
.activeLink {
  background-color: rgb(241, 241, 241) !important;
}
.gradientText {
  background: linear-gradient(
    to bottom right,
    #2c29ff,
    #2d28ff 8.1%,
    #2e26ff 15.5%,
    #3123ff 22.5%,
    #351fff 29%,
    #3a1aff 35.3%,
    #4115ff 41.2%,
    #4710ff 47.1%,
    #4f0aff 52.9%,
    #5705ff 58.8%,
    #5f00ff 64.7%,
    #ba56fd 85%,
    #b660f1 77.5%,
    #ca6ef5 84.5%,
    #db7af8 91.9%,
    #db7af8
  );
  font-weight: bold !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: slide-up 2s infinite;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  25% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.navbar {
  margin-top: 1rem;
  position: sticky;
  top: 16px;
  border: 1px solid rgba(128, 128, 128, 0.205);
  z-index: 1000;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  width: 1180px;
  .navContent {
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      position: relative;
      top: 3px;
    }

    .navLinks {
      list-style: none;
      display: flex;
      gap: 1.5rem;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &:hover,
        &:active {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
.loginButton {
  background: linear-gradient(
    rgb(255, 255, 255) 0%,
    rgb(245, 246, 247) 100%
  ) !important;
  color: black !important;
  font-weight: 500;
  border: 1px solid #d3d4d4 !important;
}
.primaryButton {
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0.5rem;
  // background-image: linear-gradient(
  //   to top right,
  //   #2c29ff,
  //   #2d28ff 8.1%,
  //   #2e26ff 15.5%,
  //   #3123ff 22.5%,
  //   #351fff 29%,
  //   #3a1aff 35.3%,
  //   #4115ff 41.2%,
  //   #4710ff 47.1%,
  //   #4f0aff 52.9%,
  //   #5705ff 58.8%,
  //   #5f00ff 64.7%,
  //   #6800fa 71%,
  //   #6e00f6 77.5%,
  //   #7400f3 84.5%,
  //   #7700f1 91.9%,
  //   #7800f0
  // );
  background: linear-gradient(rgb(54, 58, 68) 0%, rgb(3, 7, 18) 100%);
  color: white;
  font-weight: 500;
}
.madeBy {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 9999px;
  box-shadow: 0 1px 4px #00000005;
  padding: 5px;
  font-size: 14px;
  color: #475569;
  .visit {
    color: #3b82f6;
    font-size: 14px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  img {
    border-radius: 50%;
  }
}

.header {
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 48px;
    margin-bottom: 1rem;
    margin-top: 0;
    font-weight: 400;
  }

  p {
    font-size: 1.2rem;
    color: gray;
    margin-bottom: 2rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-top: 0;
  }

  .actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    .primaryButton,
    .secondaryButton {
      padding: 0.75rem 1.5rem;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      border-radius: 0.5rem;
    }

    .secondaryButton {
      background-color: white;
      color: #007bff;
      border: 1px solid #007bff;
    }
  }
}

.content {
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  .profileCardWrapper {
    display: flex;

    position: relative;
    flex-direction: column;
    .iconRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      .iconWrapper {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        border: 1px solid #f7f8f9;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.6s;
        box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
          0 3px 3px -1px rgba(8, 11, 14, 0.1);
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
            rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        }
      }
    }
  }
  .imageCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .imageCardBottom {
      border-radius: 0.5rem;
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      align-items: center;
      border: 1px solid rgba(128, 128, 128, 0.137);
      box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
        0 16px 16px -1px rgba(8, 11, 14, 0.1);
      .leftSide {
        gap: 20px;
        display: flex;
        align-items: center;
        width: 75%;
        .nameInfo {
          display: flex;
          text-align: start !important;
          flex-direction: column;
          span {
            font-weight: 500;
            font-size: 14px;
          }
          p {
            font-weight: 300;
            opacity: 0.8;
            font-size: 14px;
          }
          * {
            margin: 0;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }
  .profileCard,
  .imageCard,
  .loginCard {
    border-radius: 0.5rem;
    padding: 1.5rem;
    width: 300px;
    box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
      0 16px 16px -1px rgba(8, 11, 14, 0.1);
  }

  .profileCard {
    padding: 0rem !important;
    position: relative;

    min-width: 350px;
    height: 360px;
    background-size: cover;
    background-position: top;
    .profile {
      height: 100%;
      display: flex;
      justify-content: end;
      flex-direction: column;
      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-bottom: 1rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .buttons {
        display: flex;
        justify-content: space-around;
        position: absolute;
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: 0;
        width: 100%;
        background-color: white;
        left: 50%;
        transform: translateX(-50%);
        height: 75px;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        .followButton,
        .messageButton {
          padding: 0.5rem 1rem;
          border: none;
          cursor: pointer;
          font-size: 1rem;
          border-radius: 0.5rem;
          width: 100%;
        }

        .followButton {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: white;
          border: 1px solid #c9ced6;
          margin-right: 20px;
        }

        .messageButton {
          color: #3025ff;
          margin-left: 20px;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
          background-color: white;
          border: 1px solid #c9ced6;
        }
      }
    }
  }

  .imageCard {
    min-height: 260px;
    background-size: cover;
    background-position: center;
    border: 1px solid rgba(128, 128, 128, 0.137);
    img {
      width: 100%;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.2rem;
    }
  }

  .loginCard {
    background-repeat: no-repeat;
    min-height: 260px;
    background-size: cover;
    background-position: top;
    position: relative;

    .loginCardBottom {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      width: 100%;
      background-color: white;
      left: 50%;
      transform: translateX(-50%);
      height: 75px;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      .leftSide {
        margin-left: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        img {
          width: 30px;
          border-radius: 50%;
        }
      }
      .rightSide {
        margin-right: 15px;
      }
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1.5rem;

      a {
        color: #007bff;
        text-decoration: none;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input {
        padding: 0.75rem 1rem;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 0.5rem;
      }

      .loginButton {
        padding: 0.75rem 1.5rem;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 0.5rem;
        background: linear-gradient(
          rgb(255, 255, 255) 0%,
          rgb(245, 246, 247) 100%
        ) !important;
        color: white;
      }
    }
  }
}

.mobileDescription {
  display: none;
}
.desktopDescription {
  display: block;
}
.imageCardWrapper {
  position: relative;
}
.arrowSecond,
.arrowFirst {
  width: 50px !important;
}

.arrowFirst {
  position: absolute;
  bottom: 230px;
  right: -30px;
  transform: rotate(-86deg);
}
.arrowSecond {
  position: absolute;
  transform: rotate(-126deg);
  right: -33px;
}
.menuIcon {
  display: none;
}
.mobileMenuContent {
  display: none;
  position: relative;
  li {
    list-style: none !important;
  }
}
@media (max-width: 768px) {
  .mobileMenuContent {
    display: flex !important;
    padding: 0rem 1rem;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    border-radius: 10px;
    background-color: white;
    border: 1px solid rgba(128, 128, 128, 0.205);
    margin-top: 5px;
    z-index: 999999;
    min-height: 247px;
    button {
      width: 100%;
    }
  }
  .navbar {
    width: 95% !important;
    .navLinks {
      display: none !important;
    }
    .navContent {
      padding: 0 8px !important;
      .menuIcon {
        display: block !important;
      }
    }
  }
  .profileCard,
  .imageCardWrapper {
    position: relative;
  }

  .arrowFirst,
  .arrowSecond {
    z-index: 9999 !important;
    width: 50px !important;
    position: absolute !important;
    left: 20% !important;
    bottom: -80px !important;
  }
  .arrowFirst {
    transform: rotate(-19deg) !important;
  }
  .arrowSecond {
    bottom: -80px !important;
    left: 75% !important;
    transform: rotate(20deg) !important;
  }

  .header {
    margin-bottom: 0 !important;
    h1 {
      font-size: 36px !important;
      height: 127px !important;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobileDescription {
    display: block !important;
    width: 100% !important;
  }
  .desktopDescription {
    display: none;
  }

  .wrapper {
    width: 100% !important;
  }
  .customStyle {
    width: 75%;
  }

  .profileCard {
    width: 370px !important;
  }
  .imageCard,
  .loginCard {
    width: 320px !important;
  }
  .header {
    width: 95% !important;
  }
  .content {
    margin-top: 0 !important;
  }
  .iconRow {
    display: none !important;
  }
}
