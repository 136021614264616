/* data-testid="container" olan div elementini seçmek için */
div[data-testid="container"] {
  border-radius: 8px;
  height: 600px !important;
  border: 2px solid rgba(0, 0, 0, 0.185);
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  margin: 7rem 0;
  flex-direction: column;
  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  img {
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }
  img:hover {
    transform: rotate(-10deg);
    scale: 1.1;
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: start;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .compareImage {
    border: 1px solid gray;
  }
}

.imageSection {
  margin-bottom: 20px;
  cursor: pointer;

  img {
    max-width: 500px;
    margin-bottom: 20px;
  }

  .signUpButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

.textSection {
  flex: 1 1 100%;
  max-width: 600px;
  margin-top: 5px;
  .featuresList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    .featureItem {
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      border-radius: 8px;
      padding: 10px;
      flex: 1 1 30%;
      text-align: start;
      .icon {
        font-size: 2rem;
        color: #007bff;
        margin-bottom: 10px;
      }
      svg {
        color: black !important;
      }
      h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
        font-weight: 500;
      }
      p {
        font-size: 0.9rem;
        color: #000;
        font-weight: 400;
      }
    }
  }
}

.gradientText {
  width: 100%;
  margin-bottom: 5px !important;
  background: linear-gradient(
    to bottom right,
    #4710ff 47.1%,
    #4f0aff 52.9%,
    #5705ff 58.8%,
    #5f00ff 64.7%,
    #ba56fd 85%,
    #b660f1 77.5%,
    #ca6ef5 84.5%,
    #db7af8 91.9%,
    #db7af8
  );
  font-weight: 500 !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: slide-up 2s infinite;
}

@media (max-width: 768px) {
  .container {
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem !important;
    .gradientText {
      width: 95% !important;
    }
  }
  .textSection {
    display: flex;
    justify-content: center;
    align-items: center;
    .featuresList {
      width: 95%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .imageSection {
    img {
      left: -1px !important;
      margin-bottom: -20px;
    }
  }
  .featuresList {
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .featureItem {
      flex: 1 1 100%;
      border: 1px solid #80808057;
      box-shadow: none !important;
      height: 200px;
    }
  }

  .wrapper {
    flex-direction: column;
  }
  .imageSection {
    img {
      width: 387px !important;
    }
  }
  .container {
    margin-top: 2rem !important;
  }
}
