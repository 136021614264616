.contactFormContainer {
  display: flex;
  justify-content: center;
  color: #333;
  border: 1px solid rgba(128, 128, 128, 0.26);
  border-radius: 8px;
  padding: 20px;
}

.contactFormContent {
  display: flex;
  background-color: #fff;
  gap: 2rem;
  border-radius: 8px;
  padding: 40px;
  max-width: 1000px;
  width: 100%;
  flex-wrap: wrap;
}

.leftSide {
  flex: 1;
  text-align: left;
  min-width: 300px;
}

.rightSide {
  flex: 1;
  min-width: 300px;
}

h2 {
  font-size: 2.5em;
  margin-top: 0;
  margin-bottom: 20px;
}

p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.emailLink {
  display: block;
  color: #6c63ff;
  background: linear-gradient(
    to bottom right,
    #4710ff 47.1%,
    #4f0aff 52.9%,
    #5705ff 58.8%,
    #5f00ff 64.7%,
    #ba56fd 85%,
    #b660f1 77.5%,
    #ca6ef5 84.5%,
    #db7af8 91.9%,
    #db7af8
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-size: 1.2em;
  margin-bottom: 40px;
  text-decoration: none;
}

.socialMedia {
  display: flex;
  gap: 15px;
  margin-top: 40px;
  justify-content: flex-start;
}

.socialIcon {
  color: #999;
  font-size: 1.5em;
  transition: color 0.3s;
}

.socialIcon:hover {
  color: #2e27ff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 20px;
}

.inputField,
.textArea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  background-color: #f9f9f9;
  color: #333;
}

.textArea {
  resize: none;
  height: 150px;
}

.recaptchaContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.submitButton {
  padding: 15px;
  background: linear-gradient(rgb(54, 58, 68) 0%, rgb(3, 7, 18) 100%);
  color: white;
  font-weight: 500;
  border: 1px solid #d3d4d4 !important;
  color: #ffffff;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 107%;
}

@media (max-width: 768px) {
  .contactFormContent {
    flex-direction: column;
    padding: 20px;
    border: 1px solid #8080804a;
  }
  .contactFormContainer {
    border: none;
  }

  .leftSide,
  .rightSide {
    min-width: 100%;
  }

  h2 {
    font-size: 2em;
  }
  .inputField {
    width: 95%;
  }
  textarea {
    width: 95% !important;
  }
  .emailLink {
    font-size: 1em;
    margin-bottom: 20px;
  }
  button {
    width: 107% !important;
  }
}
