.pricingContainer {
  text-align: center;
  margin-bottom: 7rem;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  span {
    color: #6c63ff;
    background: linear-gradient(
      to bottom right,
      #4710ff 47.1%,
      #4f0aff 52.9%,
      #5705ff 58.8%,
      #5f00ff 64.7%,
      #ba56fd 85%,
      #b660f1 77.5%,
      #ca6ef5 84.5%,
      #db7af8 91.9%,
      #db7af8
    );
    font-weight: bold !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    animation: slide-up 2s infinite;
  }
}
.enhancedFeature {
  color: #7100f4;
  font-weight: bold;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #6c757d;
}

.packages {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  strong {
    font-size: 3.2rem;
  }
  .populerIcon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    animation: shake 1s infinite;
  }

  .package {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 28px;
    padding: 2rem;
    width: 300px;
    text-align: left;
    .additionalOptions {
      background: rgb(92 1 255 / 2%);
      padding: 2px 5px;
      margin-bottom: 1rem;
      border-radius: 9px;
      border: 0.5px solid #361eff3b;
      h3 {
        font-size: 0.9rem;
      }
    }
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    p {
      color: black !important;
    }
    .starter,
    .basic,
    .premium {
      width: fit-content;
      font-size: 20px;
      font-weight: 500;
      padding: 4px 14px;
      padding-bottom: 6px;
      border-radius: 25px;
      margin-top: 0 !important;
    }
    .starter {
      background-color: #a786ee28;
      border: 1px solid #a786ee;
      color: #a786ee;
    }
    .basic {
      color: #edb660;
      background-color: #edb66028;
      border: 1px solid #edb660;
    }
    .premium {
      color: #5ccf99;
      background-color: #5ccf9928;
      border: 1px solid #5ccf99;
    }
    .price {
      font-size: 1.25rem !important;
      border-bottom: 1px solid #80808042;
      padding-bottom: 1rem;
      color: #6c63ff;
      margin-top: 0 !important;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 1.5rem;

      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
    }

    .buyButton {
      color: white;
      padding: 1.25rem 1.5rem;
      width: 100%;
      cursor: pointer;
      font-size: 1rem;
      border-radius: 2rem;
      background-color: white;
      color: black;
      border: 1px solid rgba(128, 128, 128, 0.233);
      transition: background-color 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }

    &.popular {
      border: 2px solid #6c63ff;
      position: relative;

      .popularTag {
        position: absolute;
        top: -10px;
        right: -10px;
        background-image: linear-gradient(
          to top right,
          #2c29ff,
          #2d28ff 8.1%,
          #2e26ff 15.5%,
          #3123ff 22.5%,
          #351fff 29%,
          #3a1aff 35.3%,
          #4115ff 41.2%,
          #4710ff 47.1%,
          #4f0aff 52.9%,
          #5705ff 58.8%,
          #5f00ff 64.7%,
          #6800fa 71%,
          #6e00f6 77.5%,
          #7400f3 84.5%,
          #7700f1 91.9%,
          #7800f0
        );
        color: #ffffff;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        font-size: 0.75rem;
      }

      .buyButtonPopular {
        color: white;
        padding: 1.25rem 1.5rem;
        width: 100%;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2rem;
        background-image: linear-gradient(
          to top right,
          #2c29ff,
          #2d28ff 8.1%,
          #2e26ff 15.5%,
          #3123ff 22.5%,
          #351fff 29%,
          #3a1aff 35.3%,
          #4115ff 41.2%,
          #4710ff 47.1%,
          #4f0aff 52.9%,
          #5705ff 58.8%,
          #5f00ff 64.7%,
          #6800fa 71%,
          #6e00f6 77.5%,
          #7400f3 84.5%,
          #7700f1 91.9%,
          #7800f0
        );
        transition: all 0.3s;
        color: white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .packages {
    flex-direction: column;
    align-items: center;

    .package {
      max-width: 400px;
    }
  }
}
