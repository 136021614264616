.whyUseContainer {
  margin: 7rem 0;
  margin-bottom: 4rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 5px;
  }

  p {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 2rem;
    color: gray;
  }

  .features {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 sütunlu grid */
    gap: 1.5rem; /* Kartlar arasındaki boşluk */
    width: 100%; /* Tam genişlik */
  }

  .featureCard {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    text-align: left;
    box-sizing: border-box;
    height: 320px; /* Sabit yükseklik */
    display: flex;
    flex-direction: column;

    .icon {
      font-size: 2rem;
      display: flex;
      align-items: center;
      position: relative;
    }

    .iconItems {
      position: relative;
      top: 9px;
      right: -10px;
    }

    h3 {
      font-size: 1.1rem;
      white-space: nowrap;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      margin: 0;
    }

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    .images {
      position: relative;
      height: 190px; /* Resim yüksekliği */
      overflow: hidden;

      img {
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 1s ease-in-out;
        opacity: 0;
      }

      .activeImage {
        opacity: 1;
      }

      .inactiveImage {
        opacity: 0;
      }
    }
  }
}

// RESPONSIVE
@media (max-width: 1024px) {
  .whyUseContainer {
    .features {
      grid-template-columns: repeat(
        2,
        1fr
      ); /* Orta ekranlar için iki sütunlu grid */
    }
  }
}

@media (max-width: 768px) {
  .whyUseContainer {
    .outTitle,
    .outDesc {
      width: 90% !important;
    }

    .features {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .featureCard {
        width: 95% !important;
        border: 1px solid #80808030 !important;
        box-shadow: none !important;
      }
    }
  }
}
