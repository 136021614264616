.footer {
  padding: 2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  .stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;

    .statItem {
      h3 {
        font-size: 2rem;
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }

  .notification {
    background-color: #f7f8f9;
    padding: 3.5rem 3.5rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSide {
      display: flex;
      flex-direction: column;
    }
    h4 {
      font-size: 1.5rem;
      margin: 0 0 0.5rem;
      display: flex;
      font-weight: 500;
      span {
        margin-left: 0.5rem;
      }
    }

    p {
      margin: 0 0 1rem;
      font-weight: 400;
      color: gray;
    }

    .notifyButton {
      background-color: #000;
      color: #fff;
      border: none;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 3rem;
      cursor: pointer;
      width: 12rem;
      height: 3rem;
      transition: all 0.3s;
      span {
        margin-left: 0.5rem;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    text-align: left;
    background-color: #f7f8f9;
    padding: 1.5rem 1.5rem;
    border-radius: 1rem;
    div {
      h5 {
        font-size: 1rem;
        margin-bottom: 2.5rem;
        font-weight: 500;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 1rem;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .links {
    flex-direction: column;
    margin: 0 1.3rem;
    h5 {
      margin-bottom: 15px !important;
    }
  }
}
