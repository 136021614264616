.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  text-align: center;
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: lightgray; /* Set the color to light gray */
}

.closeButton {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: #f4541d;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.features {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
}

.features::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.features {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.featureCard {
  min-width: 200px;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  text-align: left;
}

.featureCard h3 {
  font-size: 16px;
  margin: 10px 0 5px;
}

.featureCard p {
  font-size: 14px;
  color: #555;
}

@media (max-width: 768px) {
  .modal {
    width: 80%;
  }
}
