.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .compareContainer {
    position: relative;
    width: 100%;
  }
}

.compareContainer {
  position: relative;
}
.floatingDivContainer {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.floatingDiv {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 5px;
  transition: transform 2s ease-in-out;
  display: flex;
  text-align: center;
  white-space: nowrap;
  font-weight: 400;
  margin: 5px;
}

.gradientText {
  background: linear-gradient(
    to bottom right,
    #2c29ff,
    #2d28ff 8.1%,
    #2e26ff 15.5%,
    #3123ff 22.5%,
    #351fff 29%,
    #3a1aff 35.3%,
    #4115ff 41.2%,
    #4710ff 47.1%,
    #4f0aff 52.9%,
    #5705ff 58.8%,
    #5f00ff 64.7%,
    #ba56fd 85%,
    #b660f1 77.5%,
    #ca6ef5 84.5%,
    #db7af8 91.9%,
    #db7af8
  );
  font-weight: 500 !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: slide-up 2s infinite;
}

@media (max-width: 768px) {
  .container {
    justify-content: center;
    align-items: center;

    margin-bottom: 20rem;
  }

  .compareContainer {
    width: 95% !important;
    height: 300px !important;
  }

  .floatingDivContainer {
    display: none;
  }

  .floatingDiv {
    width: 90%;
    max-width: none;
    margin: 10px 0;
  }
}
